<template>
  <div>
    <div class="profile-tabs">
      <p
        v-for="(tab, index) in tabList"
        :key="index || tabList.length"
        @click="clicked(tab.id)"
        :class="['tab-btn', { active: componentName === tab.id }]"
      >
        {{ $t(tab.name) }}
      </p>
    </div>
    <div class="component-contains">
      <component
        :is="componentName"
        :locale="locale"
        :isCardUpdate="isCardUpdate"
        :cardUpdateDetailState="cardUpdateDetailState"
        :cardUpdateStatus="cardUpdateStatus"
        @gatewayList="setGatewayList"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    locale: {
      type: String,
    },

    paymentDetail: {
      type: Object,
    },

    isCardUpdate: {
      type: Boolean,
    },

    cardUpdateDetailState: {
      type: Object,
    },

    cardUpdateStatus: {
      type: Object,
    },
  },
  data() {
    return {
      tabList: [
        // { name: "Plans", id: "Plans" },
        // { name: "Tickets", id: "Tickets" },
        // { name: "Devices", id: "Devices" },
      ],
      componentName: "",
      localDisplayLang: null,
      gatewayListArr: [],
      subscriberDetails: null,
    };
  },

  watch: {
    subscriptionList(val) {
      console.log("THE MAIN PROFILE IDDD --- > ", this.profileid , this.subscriberDetails.profileid )
      if (val && val.length) {
        val.forEach((ele) => {
          console.log(
            "THE STRINGGGG -- ",
            !JSON.stringify(this.tabList).includes(JSON.stringify({ name: "Manage Cards", id: "CardUpdate" }))
          );
          if (
            ele.gwprovider == "CHECKOUT" &&
            this.profileid === this.subscriberDetails.profileid &&
            !JSON.stringify(this.tabList).includes(JSON.stringify({ name: "Update Card", id: "CardUpdate" }))
          ) {
            this.tabList.push({ name: "Update Card", id: "CardUpdate" });
            return;
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig", "subscriptionList", "getIsManageCards", "profileid", "subscriberid"]),
  },

  created() {
    this.getUserInfo();

    this.setupTabList();
  },

  mounted() {
    // this.addManageCardsToList();

    // this.addDevicesListToMenu();

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.componentName = this.tabList[0].id;

    if (this.isCardUpdate && this.cardUpdateDetailState) {
      this.componentName = "CardUpdate";
    }
  },

  methods: {
    ...mapMutations(["setSubscriptionList"]),

    clicked(data) {
      this.componentName = data;
    },

    getUserInfo() {
      this.isSubProfiles = false;
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }

      this.getCurrentProfileDetails();
    },

    getCurrentProfileDetails() {
      this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;
    },

    setupTabList() {
      // Condition for admin Profile
      if (this.profileid === this.subscriberid) {
        this.tabList = [
          { name: "Plans", id: "Plans" },
          { name: "Tickets", id: "Tickets" },
        ];
      } else {
        this.tabList = [{ name: "Tickets", id: "Tickets" }];
      }

      this.addManageCardsToList();

      this.addDevicesListToMenu();
    },

    setGatewayList(val) {
      this.gatewayListArr = val;
    },

    addManageCardsToList() {

      if (this.profileid === this.subscriberDetails.profileid) {
        if (this.subscriptionList && this.subscriptionList.length) {
          this.subscriptionList.forEach((ele) => {
            if (ele.gwprovider == "CHECKOUT") {
              this.tabList.push({ name: "Update Card", id: "CardUpdate" });
              return;
            }
          });
        }
      }
    },

    addDevicesListToMenu() {
      if (this.appConfig.featureEnabled.isDevicesListEnabled && this.profileid === this.subscriberDetails.profileid) {
        this.tabList.push({ name: "Devices", id: "Devices" });
      }
    },
  },
  components: {
    Plans: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Plans.vue"),
    Devices: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Devices.vue"),
    Bills: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Bills.vue"),
    Purchases: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Purchases.vue"),
    Tickets: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Tickets.vue"),
    ActivityLog: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Activities.vue"),
    Settings: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Settings.vue"),
    CardUpdate: () => import(/* webpackChunkName: "tabs" */ "./CardUpdate.vue"),
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";

.profile-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $font-regular;
  background-color: $clr-dark-gd4;
  padding: 1.5rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  .tab-btn {
    width: fit-content;
    margin: 5px 10px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background: transparent;
    font-size: 0.95rem;
    font-weight: bold;
    color: $clr-popup-para;
    cursor: pointer;
  }

  .active {
    background: $clr-dark-gd6;
    color: $clr-light-gd2;
  }
}
.component-contains {
  padding: 20px 0 50px 0;
  margin-top: 0.3rem;
  font-family: $font-regular;
  background-color: $clr-dark-gd5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media only screen and (max-width: 576px) {
  .profile-tabs {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // padding: 1rem;
    justify-content: center;
  }
  .component-contains {
    padding: 0px;
  }
}

// @media only screen and(min-width:320px) {
//   .profile-tabs {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
// @media only screen and(min-width:480px) {
//   .profile-tabs {
//     // display: grid;
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
// @media only screen and(min-width:576px) {
//   .profile-tabs {
//     // display: grid;
//     grid-template-columns: repeat(4, 1fr);
//   }
// }
// @media only screen and(min-width:768px) {
//   .profile-tabs {
//     display: flex;
//     align-items: center;
//   }
// }
</style>
