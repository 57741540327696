<template>
  <div class="purchased-container">
    <select class="category-select" v-model="category">
      <option disabled value>Category</option>
    </select>
    <div class="purchase listing">
      <p class="category-name">{{ categoryName }}</p>
      <div class="content-details" v-for="(content, index) in purchasedContent" :key="index">
        <div class="display-info">
          <!-- <img class="content-picture" :src="content.picture" alt /> -->
          <div class="content-info">
            <p class="bold-text">{{ content.title }}</p>
            <p class="light-text">{{ content.duration }}</p>
          </div>
        </div>
        <img class="pdf-report" src="@/assets/icons/pdf-1.svg" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: "",
      categoryName: "TV Shows",
      purchasedContent: []
    };
  },
  mounted() {
    this.purchasedContent = [
      {
        // picture: require("@/assets/Images/img2.jpg"),
        title: "Money Heist",
        duration: "2 Seasons"
      },
      {
        // picture: require("@/assets/Images/img2.jpg"),
        title: "Money Heist",
        duration: "2 Seasons"
      },
      {
        // picture: require("@/assets/Images/img2.jpg"),
        title: "Money Heist",
        duration: "2 Seasons"
      }
    ];
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.purchased-container {
  padding: 4rem 2rem 2rem;
  position: relative;
  .category-select {
    width: fit-content;
    position: absolute;
    right: 4%;
    top: 7%;
    background-color: transparent;
    padding: 6px;
    border-radius: 4px;
    color: $clr-light-gd2;
    border: 1px solid $clr-light-gd4;
  }
  .category-name {
    color: $clr-popup-para;
    font-weight: 600;
    font-size: 0.9rem;
  }
}
.content-details {
  display: flex;
  align-items: center;
  // background-color: $clr-dark-gd4;
  border-radius: 10px;
  justify-content: space-between;
  // margin: 0.5rem 0rem;
  padding: 1rem;
  .display-info {
    display: flex;
    .content-picture {
      width: 100px;
      height: 60px;
    }
    .content-info {
      line-height: 15px;
      padding: 0.8rem 0rem 0rem 1rem;
      .bold-text {
        color: $clr-light-gd2;
        font-weight: 600;
      }
      .light-text {
        color: $clr-popup-para;
        font-size: 0.8em;
      }
    }
  }
  .pdf-report {
    width: 15px;
    height: 17px;
  }
}
</style>
