<template>
  <div class="device-container">
    <div>
      <div class="error-alert" v-if="showError">{{ $t(message) }}</div>
    </div>

    <div v-if="isDevicesLoading"><Loading /></div>

    <div v-if="!isDevicesLoading && allDevices.length > 0">
      <div class="devices-remaining">
      {{$t("You still have")}} {{remainingDevices}} {{$t("devices to add or replace for this month")}}.
      </div>
    </div>

    <div v-if="!isDevicesLoading" class="device-listing">
      <div
        :class="['device-details', { activeDevice: device.devicestatus === 'DISABLED' }]"
        v-for="(device, index) in allDevices"
        :key="index || device.devicestatus"
        :id="index"
      >
        <div class="flex-coloumn">
          <div class="device-changes">
            <div class="image-container">
              <img :src="deviceImage" :alt="deviceImage" />
            </div>

            <p dir="ltr" class="bold-text-device device-detail-name">
              <span class="bold-text-device">{{ device.model ? device.model : "" }}</span>
              &nbsp; : &nbsp; <br />
              <span class="bold-text-device">{{ device.devicename ? device.devicename : "" }}</span>
            </p>

            <p dir="ltr" class="device-active-status">
              <span> {{ getRelativeTime(device.lastlogin,localDisplayLang) }} </span>
              <span v-if="getLoginLocation(device.loginlocation)">&#9679;&nbsp;</span> 
              <span>{{ getLoginLocation(device.loginlocation) }}</span>
            </p>
          </div>
          <div @click="changeDeviceState(device, index)"  class="button">
            <div class="button-remove button-primary"><span class="btn-txt-color">{{$t("Remove")}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions, mapGetters } from "vuex";

import { showSnackBar, timeDifference } from "../../../../utilities";

export default {
  data() {
    return {
      totalDevices: null,
      displayNum: null,
      allDevices: [],
      enabledDevices: [],
      deviceImage: require("@/assets/icons/tv.svg"),
      isDevicesLoading: false,
      showError: false,
      message: "",
      pageNo: 1,
      remainingDevices: null,
      deviceLimit: null,
      localDisplayLang:null

    };
  },

  computed: {
    ...mapGetters(["getCountryCodesList"]),
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.getAllDevices();
  },

  methods: {
    ...mapActions(["deviceList", "updateDevice"]),

    getLoginLocation(countryCode) {
      let theCountry;

      this.getCountryCodesList.forEach((country) => {
        if (country.code === countryCode) {
          theCountry = country.name;
        }
      });

    if (theCountry) {
        return theCountry.toUpperCase();
      } else {
        return null;
      }
    },

    getRelativeTime(lastLogin,lang) {
      return timeDifference(lastLogin,lang);
    },

    getAllDevices() {
      this.isDevicesLoading = true;

      const payload = {
        archived: "NO",
      };

      this.deviceList(payload)
        .then((response) => {
          console.log("THE DEVICES LIST ---> ", response);

          if (response.data.errorcode) {
            this.showError = true;
            this.message = response.data.reason;
						this.allDevices = [];
            this.isDevicesLoading = false;
          } else {
            this.allDevices = response.data.data;
            this.deviceLimit = response.data.noofdeviceused;
            this.remainingDevices = response.data.remaininglimit;
            this.isDevicesLoading = false;
          }
        })
        .catch((error) => {
          this.isDevicesLoading = false;
          console.log("Error in fetching the Devices list -> ", error);
        });
    },
    changeDeviceState(device, index) {
      this.isDevicesLoading = true;

      const payload = {
        deviceId: device.deviceid,

        status: {
          archived: "YES",
        },
      };

      this.updateDevice(payload)
        .then((response) => {
          if (!response.data.reason) {
            showSnackBar(this.$t("Device Successfully Removed"));
		this.getAllDevices();
          } else {
            this.isDevicesLoading = false;
            showSnackBar(this.$t(response.data.reason));
          }
        })
        .catch((err) => {
          console.log("error in updating device Status -> ", err);
        });
    },
  },

  components: {
    ErrorAlert: () => import(/* webpackChunkName: "ErrorAlert" */ "@/components/Templates/ErrorAlert.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.device-container {
  padding: 1.5em 3em 2rem;
  h4 {
    color: $clr-light-gd4;
    span {
      color: $clr-light-gd3;
    }
  }
  &-placeholder {
    display: flex;
    // grid-template-columns: repeat(4, 1fr);
    // grid-column-gap: 1em;
    margin-top: 1rem;
    &-card {
      border-radius: 0.6rem;
      margin: 0.5em 0em;
      // background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      background-color: #0d0d0d !important;
      height: 200px;
      overflow: hidden;
    }
  }

  .flex-coloumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15rem;
  }

  .device-detail-name {
    margin: 1rem 0 0.5rem 0;
  }

  .device-active-status {
    font-size: 0.75rem;
    color: $clr-popup-para;
  }

  .devices-remaining {
    font-size: 1rem;
    color:  $clr-light-gd4;
    margin: 0 1rem 1rem 1rem;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 0 0;
    font-size: 1rem;
    .button-remove {
      border-radius: 5px;
      padding: 0.5rem;
      color: $clr-light-gd3;
      width: 5rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        background:  $btn-clr;
      }
    }
  }

  .device-listing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // grid-template-columns: repeat(4, 1fr);
    // padding: 1em 0em;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;
    .activeDevice {
      background-color: #262525 !important;
    }
    .device-details {
      text-align: center;
      font-family: $font-regular;
      border-radius: 0.6rem;
      line-height: 1.2rem;
      padding: 2rem;
      margin: 1rem;
      background-color: $clr-dark-gd6;
      width: 17.5rem;
      height: 17.5rem;
      .image-container {
        width: 72px;
        height: 71px;
        background-color: $clr-light-gd3;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 0.3rem;
        img {
          padding: 0px;
          width: 40px;
          margin-top: 22px;
        }
      }
      .device-state {
        // margin-top: 2rem;
        // padding: 0rem 3rem;
        font-weight: bold;
        cursor: pointer;
        font-size: 0.8rem;
      }

      .device-status {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 1rem;
      }
    }
  }
}
// .loader,
.error-alert {
  display: flex;
  justify-content: center;
  height: fit-content;
  font-size: 1rem;
  color:  $clr-light-gd4;
}
.bold-text-device {
  font-size: 1rem;
  line-height: 25px;
  color: $clr-light-gd2;
  word-wrap: break-word;
  font-weight: 600;
}
.light-text-device {
  color: $clr-popup-para;
  font-size: 0.8em;
}
.active {
  color: $btn-clr;
  font-size: 0.8rem;
}

$background-color_1: #ccc;
$background-color_2: white;
$background-color_3: #2196f3;

/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
  margin: 0 1rem 0 1rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $background-color_1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: $background-color_2;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input {
  &:checked {
    + {
      .slider {
        background-color: $background-color_3;
        &:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }
    }
  }
  &:focus {
    + {
      .slider {
        box-shadow: 0 0 1px #2196f3;
      }
    }
  }
}
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .device-container {
    // padding: 1.5em 2em 2rem;
    &-placeholder {
      grid-template-columns: repeat(2, 1fr);
    }
    .device-listing {
      justify-content: center;
      // grid-template-columns: repeat(2, 1fr);
      .device-details {
        padding: 1rem;
        margin: 1rem;
        // .device-state {
        //   margin-top: 0.5rem;
        // }
      }
    }
  }
  .bold-text-device {
    font-size: 0.7rem;
  }

  .light-text-device {
    font-size: 0.7rem;
  }

  .button {
    .button-remove {
      font-size: 0.75rem;
      padding: 0.25rem !important;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    margin: 0 0 0 1rem;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background-color_1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 9px;
      width: 9px;
      left: 4px;
      bottom: 3px;
      background-color: $background-color_2;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input {
    &:checked {
      + {
        .slider {
          background-color: $background-color_3;
          &:before {
            -webkit-transform: translateX(14px);
            -ms-transform: translateX(14px);
            transform: translateX(14px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
  }
}
</style>
